import React from 'react'
import TESTING_GROUND from '../../TESTING_GROUND'

function TESTING() {
    return (
        <div>
            <TESTING_GROUND />
        </div>
    )
}

export default TESTING
