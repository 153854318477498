import React from 'react';
import ContactMeHero from '../../ContactMeHero';
import ContactHeroInfo from '../../ContactHeroInfo';

function ContactMe() {
    return (
        <>
        {/* <ContactHeroInfo/> */}
        <ContactMeHero/>
        </>
    )
};



export default ContactMe;